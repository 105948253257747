/**
 * 3. Structure
 * ----------------------------------------------------------------------------
 */
.site-header,
.site-content,
.site-footer {
    padding-left: 6vw;
    padding-right: 6vw;
}

.inner {
    margin-left: auto;
    margin-right: auto;
    max-width: 960px;
}

.site-content {
    padding-bottom: 2.5em;
}

.site-branding,
.entry-header,
.entry-content,
.entry-footer,
.author-box,
.subscribe-box,
.page-header,
.page-content,
.nav-links,
.comments-area {
    margin-left: auto;
    margin-right: auto;
    max-width: 740px;
}
