/* PrismJS 1.15.0
https://prismjs.com/download.html#themes=prism-funky&languages=markup+css+clike+javascript */
/**
 * prism.js Funky theme
 * Based on “Polyfilling the gaps” talk slides http://lea.verou.me/polyfilling-the-gaps/
 * @author Lea Verou
 */

code[class*="language-"],
pre[class*="language-"] {
    font-family: Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace;
    text-align: left;
    white-space: pre;
    word-spacing: normal;
    word-break: normal;
    word-wrap: normal;
    line-height: 1.5;
    -moz-tab-size: 4;
    -o-tab-size: 4;
    tab-size: 4;
    -webkit-hyphens: none;
    -moz-hyphens: none;
    -ms-hyphens: none;
    hyphens: none;
}

/* Code blocks */
pre[class*="language-"] {
    padding: .4em .8em;
    margin: .5em 0;
    overflow: auto;
    background-size: 1em 1em;
}

code[class*="language-"] {
    background: $black;
    color: white;
    box-shadow: -0.3em 0 0 0.3em $black, 0.3em 0 0 0.3em $black;
}

/* Inline code */
:not(pre) > code[class*="language-"] {
    padding: .2em;
    border-radius: .3em;
    box-shadow: none;
    white-space: normal;
}

.token.comment,
.token.prolog,
.token.doctype,
.token.cdata {
    color: #aaa;
}

.token.punctuation {
    color: $gray;
}

.namespace {
    opacity: .7;
}

.token.property,
.token.tag,
.token.boolean,
.token.number,
.token.constant,
.token.symbol {
    color: $color-accent;
}

.token.selector,
.token.attr-name,
.token.string,
.token.char,
.token.builtin {
    color: $yellow;
}

.token.operator,
.token.entity,
.token.url,
.language-css .token.string,
.toke.variable,
.token.inserted {
    color: #1DE9B6;
}

.token.atrule,
.token.attr-value,
.token.keyword {
    color: #D50000;
}

.token.regex,
.token.important {
    color: orange;
}

.token.important,
.token.bold {
    font-weight: bold;
}

.token.italic {
    font-style: italic;
}

.token.entity {
    cursor: help;
}

.token.deleted {
    color: red;
}
