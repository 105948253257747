// Fonts
$font-primary: "Roboto", "Helvetica Neue", Helvetica, sans-serif;
$font-secondary: "Cousine", "Courier New", Courier, monospace;

// Colors
$color-accent: #0ff;
$black: #111;
$gray-dark: #333;
$gray: #999;
$gray-light: #f1f1f1;
$yellow: #ffff00;
