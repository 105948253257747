/**
 * 1. Reset
 * ----------------------------------------------------------------------------
 */
 article,
 aside,
 details,
 figcaption,
 figure,
 footer,
 header,
 hgroup,
 main,
 nav,
 section,
 summary {
     display: block;
 }
 
 audio,
 canvas,
 video {
     display: inline-block;
 }
 
 audio:not([controls]) {
     display: none;
     height: 0;
 }
 
 [hidden],
 template {
     display: none;
 }
 
 html {
     font-size: 100%;
     -ms-text-size-adjust: 100%;
     -webkit-text-size-adjust: 100%;
 }
 
 body {
     margin: 0;
 }
 
 a {
     background-color: transparent;
 }
 
 a:focus {
     outline: thin dotted;
 }
 
 a:active,
 a:hover {
     outline: 0;
 }
 
 abbr[title] {
     border-bottom: 1px dotted;
     text-decoration: none;
 }
 
 b,
 strong {
     font-weight: bold;
 }
 
 dfn {
     font-style: italic;
 }
 
 small {
     font-size: 80%;
 }
 
 sub,
 sup {
     font-size: 75%;
     line-height: 0;
     position: relative;
     vertical-align: baseline;
 }
 
 sup {
     top: -0.5em;
 }
 
 sub {
     bottom: -0.25em;
 }
 
 img {
     -ms-interpolation-mode: bicubic;
     border: 0;
     vertical-align: middle;
 }
 
 svg:not(:root) {
     overflow: hidden;
 }
 
 figure {
     margin: 0;
 }
 
 pre {
     overflow: auto;
 }
 
 code,
 kbd,
 pre,
 samp {
     font-family: monospace, serif;
     font-size: 1em;
 }
 
 blockquote,
 q {
     quotes: none;
 }
 
 blockquote:before,
 blockquote:after,
 q:before,
 q:after {
     content: "";
     content: none;
 }
 
 form {
     margin: 0;
 }
 
 button,
 input,
 optgroup,
 select,
 textarea {
     color: inherit;
     font: inherit;
     margin: 0;
 }
 
 button {
     overflow: visible;
 }
 
 button,
 select {
     text-transform: none;
 }
 
 button,
 html input[type="button"],
 input[type="reset"],
 input[type="submit"] {
     -webkit-appearance: button;
     cursor: pointer;
 }
 
 button[disabled],
 html input[disabled] {
     cursor: default;
 }
 
 button::-moz-focus-inner,
 input::-moz-focus-inner {
     border: 0;
     padding: 0;
 }
 
 input {
     line-height: normal;
 }
 
 input[type="checkbox"],
 input[type="radio"] {
     box-sizing: border-box;
     padding: 0;
 }
 
 input[type="number"]::-webkit-inner-spin-button,
 input[type="number"]::-webkit-outer-spin-button {
     height: auto;
 }
 
 input[type="text"],
 input[type="email"],
 input[type="url"],
 input[type="password"],
 input[type="search"],
 textarea {
     -webkit-appearance: none;
 }
 
 input[type="search"]::-webkit-search-cancel-button,
 input[type="search"]::-webkit-search-decoration {
     -webkit-appearance: none;
 }
 
 textarea {
     overflow: auto;
 }
 
 optgroup {
     font-weight: bold;
 }
 
 table {
     border-collapse: collapse;
     border-spacing: 0;
 }